<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end print-hidden">
    <!-- right -->
    <div class="p-col-12 p-sm-3" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-11 p-sm-2">
      <router-link to="/dashboard">
        <Button label="กลับสู่หน้าหลัก" icon="pi pi-arrow-left"
      /></router-link>
    </div>
    <div class="p-col-1">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i>
      ตัวแปรที่ส่งผลต่อความสามารถในการทำงานของแต่ละอาชีพ
    </template>
    <div>
      การวิเคราะห์ข้อมูลตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพในแต่ละอาชีพของผู้สูงอายุ โดยใช้เกณฑ์คะแนนรวมของ Task ในแต่ละอาชีพในการแบ่งกลุ่มผู้สูงอายุที่ "ทำงานได้สูงกว่าเกณฑ์" และ "ทำงานได้ต่ำกว่าเกณฑ์" มาทำการวิเคราะห์ด้วยการทำ Feature Selection ในแต่ละอาชีพ จนได้ผลลัพธ์ตัวแปร 3 ลำดับแรกดังต่อไปนี้
    </div>
  </Fieldset>

  <Fieldset :legend="images['career_valiable_1']">
    <img :src="plots['trade_01_1']" :style="graphStyle" />
    <img :src="plots['trade_01_2']" :style="graphStyle" />
    <img :src="plots['trade_02_1']" :style="graphStyle" />
    <img :src="plots['trade_02_2']" :style="graphStyle" />
    <img :src="plots['trade_03_1']" :style="graphStyle" />
    <img :src="plots['trade_03_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['career_valiable_2']">
    <img :src="plots['farm_01_1']" :style="graphStyle" />
    <img :src="plots['farm_01_2']" :style="graphStyle" />
    <img :src="plots['farm_02_1']" :style="graphStyle" />
    <img :src="plots['farm_02_2']" :style="graphStyle" />
    <img :src="plots['farm_03_1']" :style="graphStyle" />
    <img :src="plots['farm_03_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['career_valiable_3']">
    <img :src="plots['rubber_01_1']" :style="graphStyle" />
    <img :src="plots['rubber_01_2']" :style="graphStyle" />
    <img :src="plots['rubber_02_1']" :style="graphStyle" />
    <img :src="plots['rubber_02_2']" :style="graphStyle" />
    <img :src="plots['rubber_03_1']" :style="graphStyle" />
    <img :src="plots['rubber_03_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['career_valiable_4']">
    <img :src="plots['orchard_01_1']" :style="graphStyle" />
    <img :src="plots['orchard_01_2']" :style="graphStyle" />
    <img :src="plots['orchard_02_1']" :style="graphStyle" />
    <img :src="plots['orchard_02_2']" :style="graphStyle" />
    <img :src="plots['orchard_03_1']" :style="graphStyle" />
    <img :src="plots['orchard_03_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['career_valiable_5']">
    <img :src="plots['rice_01_1']" :style="graphStyle" />
    <img :src="plots['rice_01_2']" :style="graphStyle" />
    <img :src="plots['rice_02_1']" :style="graphStyle" />
    <img :src="plots['rice_02_2']" :style="graphStyle" />
    <img :src="plots['rice_03_1']" :style="graphStyle" />
    <img :src="plots['rice_03_2']" :style="graphStyle" />
  </Fieldset>

  


  <ModalHealthSum :is-open="isModal" @dimmer="() => (isModal = false)" />
  <MoveToTopPage />
</template>


<script>
import axios from 'axios'
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { imageEncode } from '../../Utility'
import { mixins } from '../Mixins'

import MoveToTopPage from '../../components/MovetoTop'
import ModalHealthSum from './ModalHealthSum'
import DividerWord from './DividerWord'
// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      areaId: 'ALL',
      plots: {
        trade_01_1: null,
        trade_01_2: null,
        trade_02_1: null,
        trade_02_2: null,
        trade_03_1: null,
        trade_03_2: null,
        farm_01_1: null,
        farm_01_2: null,
        farm_02_1: null,
        farm_02_2: null,
        farm_03_1: null,
        farm_03_2: null,
        rubber_01_1: null,
        rubber_01_2: null,
        rubber_02_1: null,
        rubber_02_2: null,
        rubber_03_1: null,
        rubber_03_2: null,
        orchard_01_1: null,
        orchard_01_2: null,
        orchard_02_1: null,
        orchard_02_2: null,
        orchard_03_1: null,
        orchard_03_2: null,
        rice_01_1: null,
        rice_01_2: null,
        rice_02_1: null,
        rice_02_2: null,
        rice_03_1: null,
        rice_03_2: null,
      },
      images: {
        career_valiable_1: '1.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพค้าขายอาหาร',
        career_valiable_2: '2.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพทำไร่ทำสวน',
        career_valiable_3: '3.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพกรีดยาง',
        career_valiable_4: '4.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพทำสวนผลไม้',
        career_valiable_5: '5.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพทำนา',
      },
      isModal: false
    }
  },
  computed: {
    graphStyle() {
      return {
        height: '500px'
      }
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  watch: {
    areaId:{
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        this.processedImages();
      },
    }
  },

  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    processedImages() {
      this.images = {
          career_valiable_1: '1.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพค้าขายอาหาร',
          career_valiable_2: '2.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพทำไร่ทำสวน',
          career_valiable_3: '3.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพกรีดยาง',
          career_valiable_4: '4.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพทำสวนผลไม้',
          career_valiable_5: '5.ตัวแปรที่ส่งผลกับความสามารถในการประกอบอาชีพทำนา',
      };
    },
    async fetchData() {
      await this.$store.dispatch('word/fetchGraphWords', {
        areaId: this.userArea
      })
      this.getGraphBarplot()
      //**************** get graph api*************** */
    },
    getGraphBarplot() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500
      let idx = 0
      Object.keys(this.plots).forEach((name) => {
        setTimeout(
          async () => {
            this.plots[name] = 'img/analytics/'+this.areaId+'/career/'+name+'.jpg?'+new Date().getTime();
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })
    }
  },
  components: {
    ModalHealthSum,
    MoveToTopPage
  }
}
</script>

<style>
</style>